<template>
  <v-container class="content-wrap pa-5">
    <!-- コンテンツヘッダー -->
    <info-bar
      :btns="[
        {label:'', icon:'bat', tip:'明るさ変更', event:'darkClicked'},
      ]"
      @logoutClicked="$emit('reset')"
      @darkClicked="$vuetify.theme.dark = !$vuetify.theme.dark"
    >
      <template v-slot:content-info>
        店舗名：{{ shopData.shop_name }}
      </template>
    </info-bar>

    <v-row class="mb-3">
      <!-- 本日の予約 -->
      <v-col cols="12" sm="6"
        class="d-flex pr-1"
      >
        <v-card class="flex-grow-1 align-strech">
          <v-card-title class="py-3 d-flex text-h6 font-weight-bold">
            本日の予約 {{ bookingsToday.length }}件
          </v-card-title>
          <v-data-table
            :headers="bookingsTodayTableHeaders"
            :items="bookingsToday"
            item-key="id"
            show-expand single-expand
            dense
            sort-by="time"
            hide-default-footer
            no-data-text="予約がありません"
            :footer-props="{
              'items-per-page-options': [-1],
              'items-per-page-text': '行数',
              'items-per-page-all-text': '全部'
            }"
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <p class="mt-1">詳細</p>
                <p align="center">{{ bookingTableDetail(item.id) }}</p>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!-- 本日出勤 -->
      <v-col cols="12" sm="6"
        class="d-flex pl-1"
      >
        <v-card class="pa-3 flex-grow-1 align-strech">
          <v-card-title class="pa-0 pb-3 d-flex text-h6 font-weight-bold">
            本日の出勤 {{ castsInTodayCount }}名
          </v-card-title>
          <v-sheet
            class=""
            color="rgba(100, 100, 100, .1)"
          >
            <casts-in-today
              ref="castsInToday"
              :apiAdmin="apiAdmin"
              :shopData="shopData"
              :castsData="casts"
              :shiftDate="shiftGetDate"
              height="290"
              :disabledSort="true"
              @loadedCastsInDate="setCastsInCount($event)"
              @reset="$emit('reset')"
            ></casts-in-today>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mb-5">
      <!-- 予約チャート -->
      <v-col cols="12" xl="6"
        class="pb-0"
      >
        <v-card>
          <v-card-title class="py-3 d-flex align-end">
            <span class="mr-5 font-weight-bold">１ヶ月の予約数チャート</span>
            <v-spacer></v-spacer>
            <span class="mr-5 text-subtitle-1">計 {{ bookingTotalsSum }}本</span>
            <span class="text-subtitle-1">期間 {{ statisticsPeriod }}</span>
            <div class="max-min text-subtitle-2">
              <span class="mr-1">最高 {{ highestPointBooking }}本</span>
              /
              <span class="ml-1">最低 {{ lowestPointBooking }}本</span>
            </div>
          </v-card-title>
          <v-card-text>
            <v-sheet :color="$vuetify.theme.dark ? 'black' : 'rgba(100, 100, 100, .1)'">
              <v-sparkline
                :value="bookingTotalsArray"
                :labels="dayArray"
                auto-draw
                :height="chartHeight"
                padding="7"
                smooth="1"
                :line-width="$vuetify.breakpoint.xsOnly ? 2 : 1"
                color="primary"
              >
              </v-sparkline>
            </v-sheet>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- 予約統計テーブル -->
      <v-col cols="12" sm="6" xl="3"
        class="pt-2"
        :class="$vuetify.breakpoint.xsOnly || 'pr-1'"
      >
        <v-card>
          <v-card-title class="py-3 d-flex justify-center text-h6 font-weight-bold">
            日別予約数統計
          </v-card-title>
          <v-data-table
            :headers="bookingTableHeaders"
            :items="bookingStatistics"
            item-key="datetime"
            show-expand single-expand
            no-data-text="データがありません"
            dense
            :footer-props="{
              'items-per-page-options': $vuetify.breakpoint.xsOnly ? [3,-1] : [7,-1],
              'items-per-page-text': '行数',
              'items-per-page-all-text': '全部'
            }"
          >
            <template v-slot:item.gross="{ item }">
              <v-chip small :color="getItemGrossColor(item.gross)">
                {{ item.gross }}
              </v-chip>
            </template>
            <template v-slot:item.revenue="{ item }">
              <v-chip small :color="getItemRevenueColor(item.revenue)">
                {{ item.revenue }}
              </v-chip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <p class="my-1">内訳</p>
                <p align="center">{{ bookingTableExContent(item.datetime) }}</p>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!-- キャスト別テーブル -->
      <v-col cols="12" sm="6" xl="3"
        class="pt-2"
        :class="$vuetify.breakpoint.xsOnly || 'pl-1'"
      >
        <v-card>
          <v-card-title class="py-3 d-flex justify-center text-h6 font-weight-bold">
            キャスト別予約数統計
          </v-card-title>
          <v-data-table
            :headers="castTableHeaders"
            :items="castStatistics"
            item-key="name"
            show-expand single-expand
            no-data-text="データがありません"
            dense
            sort-by="gross" sort-desc
            :footer-props="{
              'items-per-page-options': $vuetify.breakpoint.xsOnly ? [3,-1] : [7,-1],
              'items-per-page-text': '行数',
              'items-per-page-all-text': '全部'
            }"
          >
            <template v-slot:item.gross="{ item }">
              <v-chip :color="getItemCastGrossColor(item.gross)" small>
                {{ item.gross }}
              </v-chip>
            </template>
            <template v-slot:item.proceeds="{ item }">
              <v-chip :color="getItemProceedsColor(item.proceeds)" small>
                {{ item.proceeds }}
              </v-chip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <p class="mt-1">内訳</p>
                <p align="center">{{ castBookingTableExContent(item.id) }}</p>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row no-gutters class="mb-5">
      <!-- 出勤チャート -->
      <v-col cols="12" sm="6"
        class="d-flex align-stretch"
        :class="$vuetify.breakpoint.xsOnly || 'pr-1'"
      >
        <v-card class="flex-grow-1">
          <v-card-title class="py-3">
            <span class="mr-5 font-weight-bold">１ヶ月の出勤数チャート</span>
            <v-spacer></v-spacer>
            <span class="text-subtitle-1">期間 {{ statisticsPeriod }}</span>
          </v-card-title>
          <v-card-text>
            <v-sheet :color="$vuetify.theme.dark ? 'black' : 'rgba(100, 100, 100, .1)'">
              <v-sparkline
                v-if="shiftTotalsArray.length > 0"
                :value="shiftTotalsArray"
                :labels="dayArray"
                auto-draw
                :height="halfChartHeight"
                padding="7"
                line-width="2"
                smooth="1"
                color="secondary"
              >
                <template v-slot:label="item">
                  {{ item.value }}
                </template>
              </v-sparkline>
            </v-sheet>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- 出勤テーブル -->
      <v-col cols="12" sm="6"
        :class="$vuetify.breakpoint.xsOnly || 'pl-1'"
      >
        <v-card>
          <v-card-title class="py-3 d-flex text-h6 font-weight-bold">
            日別出勤数
          </v-card-title>
          <v-data-table
            :headers="shiftMonthlyTableHeaders"
            :items="shiftTotalsForTable"
            item-key="datetime"
            dense
            height="255"
            hide-default-footer
            no-data-text="出勤データがありません"
            :footer-props="{
              'items-per-page-options': [-1],
              'items-per-page-text': '行数',
              'items-per-page-all-text': '全部'
            }"
          >
            <template v-slot:item.realCount="{ item }">
              <v-chip :color="getItemShiftCountColor(item.realCount)" small>
                {{ item.realCount }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <!-- 広告統計テーブル -->
      <v-col cols="12">
        <v-card>
          <v-card-title class="py-3 d-flex justify-center text-h6 font-weight-bold">
            広告媒体別予約数
          </v-card-title>
          <v-data-table
            :headers="salesAdTableHeaders"
            :items="adStatistics"
            item-key="datetime"
            no-data-text="データがありません"
            dense
            :footer-props="{
              'items-per-page-options': $vuetify.breakpoint.xsOnly ? [3,-1] : [7,-1],
              'items-per-page-text': '行数',
              'items-per-page-all-text': '全部'
            }"
          >
            <template v-slot:item.gross="{ item }">
              <v-chip :color="getItemGrossColor(item.gross)" small>
                {{ item.gross }}
              </v-chip>
            </template>
          </v-data-table>

          <v-overlay
            v-if="!salesAds.length"
            absolute
            color="accent"
          >
            「店舗基本設定」→「利用広告媒体」から広告媒体を登録すると予約情報作成時に広告名を選択できる様になります。
          </v-overlay>
        </v-card>
      </v-col>
    </v-row>

    <!-- 月間配信統計 -->
    <!-- <v-row>
      <v-col cols="12" sm="6">
        <v-card>
          <v-card-title>
            LINE配信状況 (当月)
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>ターゲットリーチ</td><td>{{ lineStatistics.targetReach }}</td>
                </tr>
                <tr>
                  <td>配信数</td><td>{{ lineStatistics.consumption }}</td>
                </tr>
                <tr>
                  <td>残り配信可能数</td><td>{{ castingLeft }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card>
          <v-card-title>
            クーポン別利用統計 (全期間)
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">クーポン名</th>
                  <th class="text-left">利用回数</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="coupon in couponsHistory"
                  :key="coupon.coupon_id"
                >
                  <td>{{ coupon.title }}</td>
                  <td>{{ couponUseCount }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
      </v-card>
      </v-col>
    </v-row> -->

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import moment from 'moment'
import "moment/locale/ja";
import { ApiTool, BizHour, CheckTokenError } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import ContentInfoBar from '@/components/_ContentInfoBar.vue'
import CastsInDate from '@/components/_CastsInDate.vue'

export default {
  components: {
    'loader': Loader,
    'info-bar': ContentInfoBar,
    'casts-in-today': CastsInDate,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  //***************************************************
  //データ
  //***************************************************
  data() {
    return {
      bookingRecords: [],
      shiftRecords: [],
      bookingTotals: [],
      shiftTotals: [],
      bookingStatistics: [],
      castStatistics: [],
      adStatistics: [],
      bookingsToday: [],
      casts: [],
      courses: [],
      salesAds: [],
      castsInTodayCount: 0,
      lineStatistics: {targetReach: 0, consumption: 0},
      castingMaximum: 1000,
      expanded: [],
      modalMessage: '',
      loading: false,
      loadingMessage: '',
      bizHour: new BizHour(this.shopData),
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
      bookingTableHeaders: [
        // { text: '', value: 'data-table-expand'},
        { text: '日付', value: 'datetime'},
        { text: '予約総数', value: 'gross' },
        { text: 'コース料金計', value: 'revenue' },
      ],
      castTableHeaders: [
        { text: 'キャスト', value: 'name'},
        { text: '予約総数', value: 'gross' },
        // { text: 'バック', value: 'castBack' },
        // { text: '店舗売上', value: 'proceeds' },
      ],
      bookingsTodayTableHeaders: [
        {text: 'キャスト', value: 'castName'},
        {text: '時刻', value: 'time' },
      ],
      shiftMonthlyTableHeaders: [
        {text: '日付', value: 'datetime'},
        {text: '出勤キャスト', value: 'realCount' },
        {text: 'ダミー', value: 'dummyCount' },
      ],
      salesAdTableHeaders: [
        { text: '日付', value: 'datetime'},
        { text: '予約総数', value: 'gross' },
      ],
    }
  },

  //***************************************************
  //算出
  //***************************************************
  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
    chartHeight() {
      if (this.$vuetify.breakpoint.xlOnly) return 97
      else if (this.$vuetify.breakpoint.xsOnly) return 150
      else return 60
    },
    halfChartHeight() {
      if (this.$vuetify.breakpoint.xlOnly) return 75
      else if (this.$vuetify.breakpoint.xsOnly) return 150
      else return 123
    },
    shiftGetDate() {
      return moment(new Date()).format('YYYY-MM-DD')
    },
    toTime() {
      return datetime => moment(datetime).format('HH:mm')
    },
    jpDate() {
      return date => moment(date).format('M/D (dd)')
    },
    castName() {
      return castId => this.casts.find( cast => cast.cast_id === castId ).name
    },
    courseCharge() {
      return courseId => this.courses.find( course => course.course_id === courseId ).course_charge
    },
    statisticsPeriod() {
      return this.jpDate(this.monthAgoOpening) + ' ~ ' + this.jpDate(this.bizHour.getBizOpening(this.tomorrowClose))
    },
    tomorrowClose() {
      return this.bizHour.getBizClosing(moment(new Date()).add(1, 'd'))
    },
    monthAgoOpening() {
      return this.bizHour.getBizOpening(moment(new Date()).subtract(1, 'M'))
    },
    lowestPointBooking() {
      return Math.min.apply(null, this.bookingTotalsArray)
    },
    highestPointBooking() {
      return Math.max.apply(null, this.bookingTotalsArray)
    },
    bookingTotalsSum() {
      let sum = 0
      this.bookingTotals.map( totalObj => sum += totalObj.bookingTotal )
      return sum
    },
    bookingTotalToday() {
      const todayTotal = this.bookingTotals.find( totalObj => {
        return totalObj.bookingDate === moment(this.bizHour.getBizOpening(new Date())).format('MM/DD')
      })
      return todayTotal !== undefined ? todayTotal.bookingTotal : 0
    },
    dayArray() {
      const array = []
      this.bookingTotals.map( totalObj => array.push(totalObj.bookingDate.split('/')[1]) )
      return array
    },
    bookingTotalsArray() {
      const array = []
      this.bookingTotals.map( totalObj => array.push(totalObj.bookingTotal) )
      return array
    },
    shiftTotalsArray() {
      const array = []
      this.shiftTotals.map( totalObj => array.push(totalObj.realCount) )
      return array
    },
    shiftTotalsForTable() {
      const array = []
      this.shiftTotals.map( totalObj => array.push({
        datetime: this.jpDate(totalObj.shiftDate),
        realCount: totalObj.realCount,
        dummyCount: totalObj.dummyCount,
      }) )
      return array
    },
    bookingTableDetail() {
      return bookingId => {
        const booking = this.bookingRecords.find( record => record.booking_id === bookingId )
        if (booking === undefined) return

        return (booking.booking_status !== '確定' ? booking.booking_status +' ／ ' : '') + (booking.is_honshi ? '本指名' : '') +
          ' ／ 名前：' + booking.booker_name + ' ／ 電話：' + booking.booker_phone
      }
    },
    castBookingTableExContent() {
      return id => {
        const castBookingData = this.castStatistics.find( row => row.id === id )
        if (castBookingData === undefined) return

        return (this.shopData.is_incall ? ('来店' + castBookingData.incall + ' ／ ') : '') +
          '電話：' + castBookingData.phone + ' ／ ネット：' + castBookingData.net //+ ' ／ LINE：' + castBookingData.line
      }
    },
    bookingTableExContent() {
      return datetime => {
        const bookingData = this.bookingStatistics.find( row => row.datetime === datetime )
        if (bookingData === undefined) return

        return (this.shopData.is_incall ? ('来店' + bookingData.incall + ' ／ ') : '') +
          '電話：' + bookingData.phone + ' ／ ネット：' + bookingData.net //+ ' ／ LINE：' + bookingData.line
      }
    },
    getItemGrossColor() {
      return gross => {
        if (gross >= 25) return 'accent'
        else if (gross >= 15) return 'primary'
        else if (gross < 5) return 'grey'
        else if (gross < 15) return 'dark'
      }
    },
    getItemRevenueColor() {
      return revenue => {
        if (revenue >= 250000) return 'accent'
        else if (revenue >= 150000) return 'primary'
        else if (revenue < 50000) return 'dark'
      }
    },
    getItemCastGrossColor() {
      return gross => {
        if (gross >= 5) return 'accent'
        else if (gross >= 3) return 'primary'
        else if (gross === 1) return 'grey'
        else if (gross === 0) return 'dark'
      }
    },
    getItemProceedsColor() {
      return proceeds => {
        if (proceeds >= 40000) return 'accent'
        else if (proceeds >= 20000) return 'primary'
      }
    },
    getItemShiftCountColor() {
      return count => {
        if (count >= 20) return 'accent'
        else if (count >= 10) return 'primary'
        else if (count >= 5) return 'grey'
        else if (count < 5) return 'dark'
      }
    },
    castingLeft() {
      return this.castingMaximum - this.lineStatistics.consumption
    }
  },

  //***************************************************
  //ライフサイクル
  //***************************************************
  created() {
    this.adminApi.setToken(this.serverToken)

    //リダイレクトなら消去
    sessionStorage.removeItem('redirectTo')

    //outcall店は来店カラム除去
    if (!this.shopData.is_incall) {
      this.bookingTableHeaders = this.bookingTableHeaders.filter( item => item.value !== 'incall' )
      this.castTableHeaders = this.castTableHeaders.filter( item => item.value !== 'incall' )
    }

    this.loading = true
    this.loadingMessage = 'データ取得中・・・'

    this.getData()
    .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    .then(() => this.loading = false)
  },

  //***************************************************
  //メソッド
  //***************************************************
  methods: {
    async getData() {

      await Promise.all([
        this.setMonthlyBookings(),
        this.setMonthlyShifts(),
        this.getCasts(),
        this.getSalesAds(),
        this.getCourses(),
      ])

      this.setBookingStatistics()
      this.setCastStatistics()
      this.setBookingsToday()
      this.setSalesAdStatistics()
    },

    //本日出勤数文字列をセット
    setCastsInCount(castsInEmit) {
      this.castsInTodayCount = castsInEmit.count
    },

    //**************************************************
    //**************************************************
    //                    APIコール
    //**************************************************
    //キャスト取得
    //**************************************************
    getCasts() {
      return this.adminApi.getReqWithAuth('cast/').then( records => {
        if (!records || !records.length) return
        this.casts = records
      })
    },

    //**************************************************
    //広告取得
    //**************************************************
    getSalesAds() {
      return this.adminApi.getReqWithAuth('sales-ad/').then( records => {
        if (!records || !records.length) return
        this.salesAds = records
      })
    },

    //**************************************************
    //コース取得
    //**************************************************
    getCourses() {
      return this.adminApi.getReqWithAuth('course/').then( records => {
        if (!records || !records.length) return
        this.courses = records
      })
    },

    //**************************************************
    //本日の予約セット
    //**************************************************
    setBookingsToday() {
      this.bookingRecords.map( record => {
        const nowDate = this.bizHour.getBizOpening(new Date())

        if (this.jpDate(record.start_at) === this.jpDate(nowDate)) {
          this.bookingsToday.push({
            castName: this.castName(record.cast_id),
            time: this.toTime(record.start_at) + ' ~ ' + this.toTime(record.end_at),
            id: record.booking_id,
          })
        }
      })
    },

    //**************************************************
    //予約統計
    //**************************************************
    setMonthlyBookings() {
      const date = moment(this.monthAgoOpening)

      //予約統計配列の初期化
      while (date.diff(this.tomorrowClose, 'hours') <= 0) {
        this.bookingTotals.push({bookingDate: date.format('M/D'), bookingTotal: 0})
        date.add(1, 'd')
      }

      const apiPartial = 'booking/range/'
      const  query =  { fromDate: this.monthAgoOpening, toDate: this.tomorrowClose }

      return this.adminApi.getReqWithAuth(apiPartial, query).then( results => {
        if (!results || !results.length) return

        results.map( bookingRecord => {
          if (bookingRecord.booking_status !== 'キャンセル' && bookingRecord.booking_type !== 'ダミー') {
            this.bookingRecords.push(bookingRecord)

            const bookingDate = moment(this.bizHour.getBizOpening(bookingRecord.start_at)).format('M/D')

            const index = this.bookingTotals.findIndex( totalObj => totalObj.bookingDate === bookingDate )
            if (index >= 0) this.bookingTotals[index].bookingTotal++
          }
        })
      })
    },

    //**************************************************
    //出勤統計
    //**************************************************
    setMonthlyShifts() {
      const startShiftDate = moment(this.monthAgoOpening).format('YYYY-MM-DD')
      const endShiftDate = moment(this.bizHour.getBizOpening(this.tomorrowClose)).format('YYYY-MM-DD')
      const date = moment(this.monthAgoOpening)

      //出勤統計配列の初期化
      while (date.diff(this.tomorrowClose, 'hours') <= 0) {
        this.shiftTotals.push({shiftDate: date.format('YYYY-MM-DD'), realCount: 0, dummyCount: 0})
        date.add(1, 'd')
      }

      const apiPartial = 'shift/count/'
      const  query = {fromDate: startShiftDate, toDate: endShiftDate}

      return this.adminApi.getReqWithAuth(apiPartial, query).then( results => {
        if (!results || !results.length) return
        this.shiftRecords = [...results]

        results.map( count => {
          const shiftDate = moment(count.shift_date).format('YYYY-MM-DD')

          const index = this.shiftTotals.findIndex( totalObj => totalObj.shiftDate === shiftDate )
          if (index >= 0) {
            this.shiftTotals[index].realCount = count.real_count
            this.shiftTotals[index].dummyCount = count.dummy_count
          }
        })
      })
    },

    //**************************************************
    //予約統計テーブル
    //**************************************************
    setBookingStatistics() {
      this.bookingRecords.map( record => {
        if (this.bookingStatistics.length === 0) {
          this.bookingStatistics.push({
            datetime: this.jpDate(this.bizHour.getBizOpening(record.start_at)),
            gross: 1,
            incall: record.booking_type === '来店' ? 1 : 0,
            phone: record.booking_type === '電話' ? 1 : 0,
            net: record.booking_type === 'ネット' ? 1 : 0,
            // line: record.booking_type === 'LINE' ? 1 : 0,
            revenue: this.courseCharge(record.course_id),
          })
        } else if (this.jpDate(this.bizHour.getBizOpening(record.start_at)) === this.bookingStatistics[this.bookingStatistics.length - 1].datetime) {
        //同じ日付の予約に対して
          this.bookingStatistics[this.bookingStatistics.length - 1].gross++
          this.bookingStatistics[this.bookingStatistics.length - 1].revenue += this.courseCharge(record.course_id)

          switch (record.booking_type) {
            case '来店':
              this.bookingStatistics[this.bookingStatistics.length - 1].incall++
              break;

            case '電話':
              this.bookingStatistics[this.bookingStatistics.length - 1].phone++
              break;

            case 'ネット':
              this.bookingStatistics[this.bookingStatistics.length - 1].net++
              break;

            // case 'LINE':
            //   this.bookingStatistics[this.bookingStatistics.length - 1].line++
            //   break;
          }
        } else {
        //日付が変わった予約に対して
          this.bookingStatistics.push({
            datetime: this.jpDate(this.bizHour.getBizOpening(record.start_at)),
            gross: 1,
            incall: record.booking_type === '来店' ? 1 : 0,
            phone: record.booking_type === '電話' ? 1 : 0,
            net: record.booking_type === 'ネット' ? 1 : 0,
            // line: record.booking_type === 'LINE' ? 1 : 0,
            revenue: this.courseCharge(record.course_id),
          })
        }
      })
    },

    //**************************************************
    //キャスト別予約統計テーブル
    //**************************************************
    setCastStatistics() {
      this.casts.map( cast => {
        this.castStatistics.push({
          id: cast.cast_id,
          name: this.castName(cast.cast_id),
          gross: 0,
          incall: 0,
          phone: 0,
          net: 0,
          line: 0,
          castBack: 0,
          proceeds: 0,
        })
      })

      this.bookingRecords.map( record => {
        const index = this.castStatistics.findIndex( cast => cast.id === record.cast_id )

        if (index > -1) {
          this.castStatistics[index].gross++
          this.castStatistics[index].castBack += 6000
          this.castStatistics[index].proceeds += 8000 // (record.course_charge - record.cast_back)

          switch (record.booking_type) {
            case '来店':
              this.castStatistics[index].incall++
              break;

            case '電話':
              this.castStatistics[index].phone++
              break;

            case 'ネット':
              this.castStatistics[index].net++
              break;

            // case 'LINE':
            //   this.castStatistics[index].line++
            //   break;
          }
        }
      })
    },

    //**************************************************
    //広告統計テーブル
    //**************************************************
    setSalesAdStatistics() {
      //テーブルヘッダー生成
      this.salesAds.map( row => {
        this.salesAdTableHeaders.push({text: row.ad_name, value: 'ad-' + row.sales_ad_id})
      })

      //予約履歴からテーブルデータ挿入
      this.bookingRecords.map( record => {
        const mmdd = moment(this.bizHour.getBizOpening(record.start_at)).format('M/D')

        if (this.adStatistics.length === 0) {
          this.adStatistics.push({
            datetime: mmdd,
            gross: 1,
          })

          this.salesAds.map( row => {
            this.adStatistics[0]['ad-' + row.sales_ad_id] = row.sales_ad_id === record.sales_ad_id ? 1 : 0
          })
        } else if (mmdd === this.adStatistics[this.adStatistics.length - 1].datetime) {
          this.adStatistics[this.adStatistics.length - 1]['gross']++
          if (record.sales_ad_id) this.adStatistics[this.adStatistics.length - 1]['ad-' + record.sales_ad_id]++
        } else {
          this.adStatistics.push({
            datetime: mmdd,
            gross: 1,
          })

          this.salesAds.map( row => {
            this.adStatistics[this.adStatistics.length - 1]['ad-' + row.sales_ad_id] = row.sales_ad_id === record.sales_ad_id ? 1 : 0
          })
        }
      })
    },
  }
}
</script>

<style scoped>
.max-min {
  position: absolute;
  top: 60px;
  right: 25px;
}
>>> .v-card__text text {
  /* sparkline label */
  font-size: 5px;
}
>>> .v-data-table__wrapper tbody >tr:nth-child(odd) {
  background-color: rgba(100, 100, 100, .1);
}
>>> .v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
  min-height: 30px !important;
}
>>> .v-data-table-header-mobile__wrapper .v-select {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
</style>
